import classNames from "classnames"
import React, { PropsWithChildren, useEffect } from "react"

import { Color } from "../../../constants/V2/color"
import PillButton from "../../elements/V2/Buttons/PillButton"
import TextButton from "../../elements/V2/Buttons/TextButton"
import GatsbyStoryblokImage from "../../elements/V2/GatsbyStoryblokImage"
import ArrowIcon from "../../elements/V2/Icons/ArrowIcon"
import Section from "../../elements/V2/Section"
import Typography from "../../elements/V2/Typography"

import {
  getAccentColor,
  getBackgroundColorClass,
  getTextColorClass,
} from "@utils/V2/color"

type Props = {
  color: Color
  classnames: string
  onClick: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
}

const CloseIcon = ({ color, classnames, onClick }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(classnames, getTextColorClass(color))}
      onClick={onClick}
    >
      <rect
        y="14.1406"
        width="20"
        height="2.5"
        transform="rotate(-45 0 14.1406)"
        fill="currentColor"
      />
      <rect
        x="1.76953"
        width="20"
        height="2.5"
        transform="rotate(45 1.76953 0)"
        fill="currentColor"
      />
    </svg>
  )
}

type FooterProps = {
  body: string
  imageUrl: string
  imageAlt: string
  ctaText: string
  ctaUrl: string
  ctaColor: Color
}

type CoveredFooterModalProps = FooterProps & {
  style: "cover"
  title: string
  // TODO: Update this type from any to Color enum. currently causes issues with footer props
  textColor?: any
}

type ContainedFooterModalProps = FooterProps & {
  style: "contain"
  title?: string
  // TODO: Update this type from any to Color enum. currently causes issues with footer props
  backgroundColor?: any
}

export type ModalProps = PropsWithChildren<{
  backgroundColor: Color
  title?: string
  body?: string
  buttonText?: string
  buttonLink?: string
  buttonBorderColor?: Color
  footer?: CoveredFooterModalProps | ContainedFooterModalProps
  isOpen: boolean
  onClick: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
}>

const Modal = ({
  backgroundColor,
  title,
  body,
  buttonText,
  buttonLink,
  buttonBorderColor,
  footer,
  isOpen,
  onClick,
  children,
}: ModalProps) => {
  useEffect(() => {
    const bodyElement = document.getElementsByTagName("body")
    if (isOpen) {
      bodyElement[0].style.overflow = "hidden"
    } else {
      bodyElement[0].style.overflow = "scroll"
    }

    return () => {
      bodyElement[0].style.overflow = "scroll"
    }
  }, [isOpen])

  const footerTextColor =
    footer?.style === "contain"
      ? getAccentColor(footer.backgroundColor)
      : footer?.textColor || Color.Charcoal

  return (
    <Section
      className={classNames(
        "fixed left-0 top-0 z-50 grid h-full w-full place-items-center overflow-x-auto bg-black-v2/50",
        "py-gutter-xs-v2 sm-v2:py-gutter-sm-v2 md-v2:py-gutter-md-v2 lg-v2:py-gutter-lg-v2 xl-v2:py-gutter-xl-v2",
        {
          hidden: isOpen === false,
        }
      )}
    >
      <div
        className={classNames(
          "flex max-w-[600px] flex-col gap-24-v2 rounded-20-v2 px-16-v2 pt-16-v2 sm-v2:gap-32-v2 sm-v2:px-24-v2",
          getBackgroundColorClass(backgroundColor),
          {
            "pb-24-v2": footer?.style === "contain",
          }
        )}
      >
        <div className="flex flex-col">
          <CloseIcon
            classnames="self-end cursor-pointer"
            color={getAccentColor(backgroundColor)}
            onClick={onClick}
          />
          {title && (
            <Typography
              className="mb-8-v2"
              font="grotesk"
              weight="medium"
              size="lead-lg"
              color={getAccentColor(backgroundColor)}
              text={title}
            />
          )}
          {body && (
            <Typography
              font="grotesk"
              weight="book"
              size="body-md"
              color={getAccentColor(backgroundColor)}
              text={body}
            />
          )}
          {buttonText && buttonBorderColor ? (
            <PillButton
              style="bordered"
              size="large"
              color={buttonBorderColor}
              text={buttonText}
              linkUrl={buttonLink}
              className="mt-24-v2 self-start"
              overrideTextColor={buttonBorderColor}
            />
          ) : undefined}
        </div>

        <div className="pb-24-v2 lg-v2:pb-48-v2">{children}</div>

        {footer ? (
          <div
            className={classNames(
              "grid grid-cols-2 rounded-sm",
              {
                "mx-[-24px] rounded-b": footer.style === "cover",
              },
              footer.style === "contain"
                ? getBackgroundColorClass(footer.backgroundColor)
                : null
            )}
          >
            <div
              className={classNames(
                "col-span-full px-24-v2 py-24-v2 sm-v2:col-span-1"
              )}
            >
              {footer.title && (
                <Typography
                  font="grotesk"
                  weight="medium"
                  size="lead-sm"
                  color={footerTextColor}
                  text={footer.title}
                />
              )}

              <Typography
                font="grotesk"
                weight="book"
                size="body-md"
                color={footerTextColor}
                text={footer.body}
              />
              <TextButton
                text={footer.ctaText}
                style="icon"
                color={footer.ctaColor || footerTextColor}
                linkUrl={footer.ctaUrl}
                icon={<ArrowIcon />}
                className="mt-8-v2"
              />
            </div>
            <GatsbyStoryblokImage
              aspectRatio="16:9"
              loadType="eager"
              image={footer.imageUrl}
              alt={footer.imageAlt}
              className="col-span-full sm-v2:col-span-1"
            />
          </div>
        ) : undefined}
      </div>
    </Section>
  )
}

export default Modal
